import React from "react";
import AnimeList from "../components/AnimeList";
import * as errorCodes from '../errorCodes';
import './Schedule.css';
import '../components/Slider.css';

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useWindowSize from "../hooks/useWindowSize";

function Schedule(props) {
  const navigate = useNavigate();
  
  const [isValidSession, setIsValidSession] = useState(false);
  const [username, setUsername] = useState('username');
  const [profilePicture, setProfilePicture] = useState('https://cdn.myanimelist.net/images/questionmark_50.gif');
  const [upToDateToggle, setUpToDateToggle] = useState(false);
  const windowSize = useWindowSize();

  function validateUserSession() {
    const userToken = localStorage.getItem('userToken');
    let userTokenExpiration = Date.parse(localStorage.getItem('tokenExpirationDate'));
    const currentDate = new Date();
    if( !userToken || !userTokenExpiration || (currentDate > userTokenExpiration) )
      throw new Error(errorCodes.INVALID_SESSION);
  }

  useEffect(() => {
    try {
      validateUserSession();
      setIsValidSession(true);
    } catch(err) {
      // En caso de que haya expirado la sesion, el usuario vuelve a la pantalla de login
      signOut();
    }

    const newUsername = localStorage.getItem('username');
    if(newUsername)
      setUsername(newUsername);

    const newProfilePicture = localStorage.getItem('profilePicture');
    if(newProfilePicture)
      setProfilePicture(newProfilePicture);
  }, []);

  function signOut() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('tokenExpirationDate');
    localStorage.removeItem('username');
    localStorage.removeItem('profilePicture');

    navigate('/');
  }

  function handleCheckboxClick() {
    setUpToDateToggle(!upToDateToggle);
  }

  function toggleMenu() {
    const menu = document.querySelector('.menu');

    if(menu.classList.contains('opened-menu'))
      menu.classList.remove('opened-menu');
    else
      menu.classList.add('opened-menu');
  }

  function openMenu() {
    const menu = document.querySelector('.menu');

    if(!menu.classList.contains('opened-menu'))
      menu.classList.add('opened-menu');
  }

  function closeMenu() {
    const menu = document.querySelector('.menu');

    if(menu.classList.contains('opened-menu'))
      menu.classList.remove('opened-menu');
  }

  return (
    <>
      <nav className='navbar'>
        <h1 id='logo'>{ (windowSize.width > 960) ? '9Schedule' : '9S' }</h1>
        <div className='user-info-container menu'
          onMouseOut={ (windowSize.width > 960) ? closeMenu : undefined} onClick={(windowSize.width <= 960) ? toggleMenu : undefined}>

          <a className='user-info' onMouseOver={ (windowSize.width > 960) ? openMenu : undefined } >
            <span className='username noselect'>{username}</span>
            <img className='user-profile-picture' src={profilePicture} alt='users profile pic'/>
          </a>
          <ul>
            <li className="visit-profile-btn-container">
              <a className='visit-profile-btn noselect' href={'https://myanimelist.net/profile/' + (username || '')} target='_blank' >Visit MAL profile</a>
            </li>
            <li className="sign-out-btn-container" onClick={signOut}>
              <p className='noselect'>Sign out</p>
            </li>
          </ul>
        </div>
      </nav>
      <div className='up-to-date-container'>
        <span className='up-to-date-text noselect'>
          Show up-to-date anime
        </span>
        <label className="switch">
          <input id="cbx" type="checkbox" onClick={handleCheckboxClick}/>
          <span className="slider round" ></span>
        </label>
      </div>
      {isValidSession && <AnimeList upToDateToggle={upToDateToggle}/>}
      
    </>
  );
}

export default Schedule;