import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import Login from './views/Login';
import Schedule from './views/Schedule';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login/>}/>
        <Route path='/schedule' exact element={<PrivateRoute/>}>
          <Route path="/schedule" exact element={<Schedule/>}/>
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;
