import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute() {
    const isAuthenticated = localStorage.getItem('userToken');

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;