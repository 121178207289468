import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import randomString from 'randomstring';
import * as Constants from '../Constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { ReactComponent as LoginBackgroundImage } from '../images/login_background.svg';

function Login(props) {
  const navigate = useNavigate();
  const [loginMessage, setLoginMessage] = useState('');

  function generateCodeVerifier() {
    return randomString.generate(128);
  }

  function handleLoginClick() {
    authRedirection();
  }

  async function authenticate() {
    // llamar al server para autenticar usuario
    // si es exitoso se guarda el token con el nombre del usuario en localStorage y se redirecciona a "/"
    // en caso de error se muestra un mensaje y el usuario permanece en pantalla de login
    try {
      const res = await axios.post(Constants.SERVER_LOGIN_URL, {
        authCode: new URLSearchParams(window.location.search).get('code'),
        codeVerifier: window.sessionStorage.getItem('codeVerifier')
      });
      const { username, profilePicture } = res.data;
      const userToken = res.data.token.value;
      const tokenExpirationDate = res.data.token.expirationDate;

      localStorage.setItem('userToken', userToken);
      localStorage.setItem('tokenExpirationDate', tokenExpirationDate);
      localStorage.setItem('username', username);
      localStorage.setItem('profilePicture', profilePicture);
      
      navigate('/schedule');
    } catch(err) {
      setLoginMessage('Couldn\'t connect to the server. Please try again later');
    }
  }

  function authRedirection() {
    const codeVerifier = generateCodeVerifier();

    const authUrlParams = {
      response_type: 'code',
      client_id: Constants.CLIENT_ID,
      state: 'returned_from_callback',
      redirect_uri: Constants.SCHEDULE_WEB_URL,
      code_challenge: codeVerifier,
      code_verifier: codeVerifier,
      code_challenge_method: 'plain'
    };
    const authParams = new URLSearchParams(authUrlParams);

    window.sessionStorage.setItem('codeVerifier', codeVerifier);
    window.location.href = Constants.MY_ANIME_LIST_API_URL + '?' + authParams.toString();
  }
  
  useEffect(() => {
    //Runs only on the first render
    console.log(window.location.search);
    let searchParams = new URLSearchParams(window.location.search);
    
    if(localStorage.getItem('userToken') && process.env.NODE_ENV === 'production') { // Redirect saved user to main page
      console.log("redirect to /schedule");
      navigate('/schedule');
    }

    if(searchParams.get('state')) {
      setLoginMessage('Loading...');
      authenticate();
    }
  }, []);

  return (
    <div className='login'>
      <div className='login-container'>
        <div className='welcome-message'>
          <h2>Welcome to </h2><h2 className='login-logo'>9Schedule</h2>
        </div>
        <h3 className='description-message'>Keep track of the current anime season</h3>
        <button className='login-button' onClick={handleLoginClick}>Login with MyAnimeList</button>
        <p className='login-message'>{loginMessage}</p>
      </div>
      <LoginBackgroundImage className='login-footer-img'/>
      

    </div>
  );
}

export default Login;