import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import * as Constants from '../Constants';
import Anime from './Anime';
import './AnimeList.css';
import CubeLoading from "./CubeLoading";
//import { ANIME_LIST_MOCK } from "../mock/animeList";
function AnimeList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [animeList, setAnimeList] = useState([]);
  const [minutesPassed, setMinutesPassed] = useState(0);

  function parseAnimeList(animeList) {
    return animeList.map((anime) => ({
        ...anime,
        timeUntilNextEpisode: undefined,
      }));
  }

  async function fetchUserAnimeList(userToken) {
    const res = await axios.get(Constants.FETCH_USER_ANIME_LIST_URL, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    let list = res.data;
    list = parseAnimeList(list);
    return list;
  }
  
  function updateAiringTimes(airingTimes, list) {
    let newAnimeList = list.slice(); // Duplico el array para no modificar el original

    if(!airingTimes)
      return;

    airingTimes.forEach(airingTime => {
      let i = newAnimeList.findIndex(anime => anime.id === airingTime.idMal);
      if(i >= 0 && airingTime.nextAiringEpisode) {
        newAnimeList[i].episodesReleased = airingTime.nextAiringEpisode.episode - 1;
        newAnimeList[i].timeUntilNextEpisode = airingTime.nextAiringEpisode.timeUntilAiring;
      }
    });

    //setAnimeList(newAnimeList);
    return newAnimeList;
  }

  async function fetchAiringTimes(userToken, list) {
    if(list.length === 0)
      return;

    let malIds = [];
    
    list.forEach(anime => {
      if(anime.status === Constants.AIRING_STATUS || anime.status === Constants.NOT_AIRED_YET_STATUS)
        malIds.push(anime.id);
    });

    console.log(malIds);
    const res = await axios.post(Constants.FETCH_ANIME_AIRING_TIMES_URL, {
      malIds
    },
    {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    let airingTimes = res.data;
    return airingTimes;
  }

  async function updateWatchedEpisodes(id, newEpisodesWatched) {
    let newAnimeList = [...animeList];
    let index = newAnimeList.findIndex(anime => {
      return (anime.id === id);
    });

    newAnimeList[index].episodesWatched = newEpisodesWatched;
    setAnimeList(newAnimeList);
  }

  async function dropAnime(id) {
    let newAnimeList = [...animeList];
    let index = newAnimeList.findIndex(anime => {
      return (anime.id === id);
    });

    newAnimeList.splice(index, 1); // Remuevo el anime de la lista
    setAnimeList(newAnimeList);
  }

  async function updateScore(id, score) {
    let newAnimeList = [...animeList];
    let index = newAnimeList.findIndex(anime => {
      return (anime.id === id);
    });

    newAnimeList[index].score = score;
    setAnimeList(newAnimeList);
  }

  useEffect(() => {

    // Declaro la función acá para poder usar async/await adentro de useEffect()
    async function loadUserList() {
      const userToken = localStorage.getItem('userToken');
      
      let list = await fetchUserAnimeList(userToken); //ANIME_LIST_MOCK;
      setAnimeList(list);
      let airingTimes = await fetchAiringTimes(userToken, list);
      list = updateAiringTimes(airingTimes, list);
      setAnimeList(list);
      setIsLoading(false);
    }

    try {
      loadUserList();

      let timeUntilNextEpisodeTimer = setInterval(() => {
        setMinutesPassed(prevMinutesPassed => ++prevMinutesPassed);
      }, 60 * 1000);

      return () => clearInterval(timeUntilNextEpisodeTimer);

    } catch(err) {
      console.log(err.message);
    }
  }, []);

  function renderContent() {
    console.log(animeList)
    if(isLoading)
      return <CubeLoading/>;
    else if(animeList && animeList.length > 0)
      return (
        <div className='anime-list'>
          {animeList.map((anime) => (
            <Anime key={anime.id} {...anime} minutesPassed={minutesPassed} 
            upToDateToggle={props.upToDateToggle} updateWatchedEpisodes={updateWatchedEpisodes} dropAnime={dropAnime} updateScore={updateScore}/>
          ))}
        </div>
      );
    else
      return (
        <div className='empty-list'>
          <p className='empty-list-face'>:'(</p>
          <p>We didn't find any airing anime in your list.</p>
          <p>Check the current season's anime <a target='_blank' href="https://myanimelist.net/anime/season">here.</a></p>
        </div>
      );
  }

  return <> {renderContent()} </>;
}

export default AnimeList;